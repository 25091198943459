exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-news-article-js": () => import("./../../../src/pages/news/news-article.js" /* webpackChunkName: "component---src-pages-news-news-article-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-projects-strapi-project-slug-js": () => import("./../../../src/pages/projects/{strapiProject.Slug}.js" /* webpackChunkName: "component---src-pages-projects-strapi-project-slug-js" */),
  "component---src-pages-projects-tag-strapi-tag-tag-name-js": () => import("./../../../src/pages/projects/tag/{strapiTag.tagName}.js" /* webpackChunkName: "component---src-pages-projects-tag-strapi-tag-tag-name-js" */),
  "component---src-pages-strapi-page-slug-js": () => import("./../../../src/pages/{strapiPage.Slug}.js" /* webpackChunkName: "component---src-pages-strapi-page-slug-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */)
}

